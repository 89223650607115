import React from "react";

function Clubs() {
  return (
    <>
      <section className="club-section">
        <p className="club-heading">Club</p>
      </section>
    </>
  );
}

export default Clubs;