import React from "react";

function Clans() {
  return (
    <>
      <section className="clan-section">
        <p className="clan-heading">Clan</p>
      </section>
    </>
  );
}

export default Clans;